var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Suspense, lazy, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Switch, withRouter } from "react-router";
import { Route, Redirect, useLocation } from "react-router-dom";
import DocumentTitle from "react-document-title";
import * as Sentry from "../sentry";
import Banner from "./Banner";
import Navigation from "./Navigation";
import Footer from "./Footer";
import chat from "../chat";
import metrics from "../metrics";
import Spinner from "./presentational/Spinner";
const Careers = lazy(() => import("./Careers"));
const Dashboard = lazy(() => import("./Dashboard"));
const Docs = lazy(() => import("./Docs"));
const Demos = lazy(() => import("./Demos"));
const Home = lazy(() => import("./Home"));
const Login = lazy(() => import("./Login"));
const Signup = lazy(() => import("./Signup"));
const Settings = lazy(() => import("./Settings"));
const Terms = lazy(() => import("./Terms"));
const Privacy = lazy(() => import("./Privacy"));
const loadDeferredScripts = () => {
    if (!window.voiceryDeferredScriptsLoaded) {
        window.voiceryDeferredScriptsLoaded = true;
        metrics.init();
        if (window.env.SENTRY_DSN) {
            Sentry.init({
                dsn: window.env.SENTRY_DSN,
                environment: window.env.SENTRY_ENVIRONMENT,
                release: __GIT_HASH__,
                blacklistUrls: [/chat-assets.frontapp.com/]
            });
        }
        if (window.env.FRONT_CHAT_ID) {
            chat.init(window.env.FRONT_CHAT_ID);
        }
    }
};
// Load deferred scripts on click or scroll.
window.addEventListener("scroll", () => setTimeout(loadDeferredScripts, 1000), {
    once: true
});
window.addEventListener("click", () => setTimeout(loadDeferredScripts, 1000), {
    once: true
});
const secure = (Component) => {
    const SecureComponent = props => {
        const location = useLocation();
        const { token: authenticated } = useSelector((state) => state.auth.data);
        if (authenticated) {
            return React.createElement(Component, Object.assign({}, props));
        }
        return (React.createElement(Redirect, { to: { pathname: "/login", state: { from: location.pathname } } }));
    };
    return SecureComponent;
};
const Loading = () => (React.createElement("div", { style: {
        flexGrow: 0.75,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    } },
    React.createElement(Spinner, { size: 5 })));
const TitleRoute = props => {
    const { title } = props, rest = __rest(props, ["title"]);
    const docTitle = title
        ? `${title} | Voicery Text-to-Speech`
        : "Voicery Text-to-Speech";
    return (React.createElement(DocumentTitle, { title: docTitle },
        React.createElement(Route, Object.assign({}, rest))));
};
const App = () => {
    const location = useLocation();
    const [lastLoc, setLastLoc] = useState("internet");
    useEffect(() => {
        if (lastLoc !== location.pathname) {
            metrics.trackPageLoad({
                url: location.pathname,
                hash: location.hash,
                from: lastLoc
            });
            setLastLoc(location.pathname);
        }
    }, [location.pathname, location.hash, lastLoc]);
    return (React.createElement(DocumentTitle, { title: "Voicery Text-to-Speech" },
        React.createElement(React.Fragment, null,
            React.createElement(Banner, null, "Voicery shut down in October 2020 and no longer provides text-to-speech services."),
            React.createElement("div", { style: { position: "relative" } },
                React.createElement(Route, { path: "/", component: Navigation }),
                React.createElement("main", null,
                    React.createElement(Suspense, { fallback: React.createElement(Loading, null) },
                        React.createElement(Switch, null,
                            React.createElement(TitleRoute, { title: "", exact: true, path: "/", component: Home }),
                            React.createElement(TitleRoute, { title: "Login", path: "/login", component: Login }),
                            React.createElement(TitleRoute, { title: "Sign Up", path: "/signup", component: Signup }),
                            React.createElement(TitleRoute, { title: "Careers", path: "/careers", component: Careers }),
                            React.createElement(TitleRoute, { title: "Documentation", path: "/docs", component: Docs }),
                            React.createElement(TitleRoute, { title: "Demos", path: "/demos", component: Demos }),
                            React.createElement(TitleRoute, { title: "Dashboard", path: "/dashboard", component: secure(Dashboard) }),
                            React.createElement(TitleRoute, { title: "Settings", path: "/settings", component: secure(Settings) }),
                            React.createElement(TitleRoute, { title: "Terms of Use", path: "/terms", component: Terms }),
                            React.createElement(TitleRoute, { title: "Privacy Policy", path: "/privacy", component: Privacy }),
                            React.createElement(Redirect, { to: "/" })))),
                React.createElement(Route, { path: "/", component: Footer })))));
};
export default withRouter(App);
