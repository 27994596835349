import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
export default function ScrollManager() {
    const { pathname, hash } = useLocation();
    const [curPathname, setCurPathname] = useState("");
    useEffect(() => {
        window.scrollTo(0, 0);
        setCurPathname(pathname);
    }, [pathname]);
    useEffect(() => {
        if (pathname !== curPathname && hash) {
            const scrollToHash = () => {
                const elem = document.querySelector(hash);
                if (elem) {
                    elem.scrollIntoView({ behavior: "smooth", block: "start" });
                }
            };
            setTimeout(scrollToHash, 2000);
        }
    }, [pathname, hash, curPathname]);
    return null;
}
