var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import { createAsyncActionReducer } from "./api";
import { listKeys, createKey, deleteKey } from "../actions/keys";
const data = createReducer({})
    .handleAction(listKeys.success, (_state, action) => action.payload.reduce((acc, key) => (Object.assign(Object.assign({}, acc), { [key.value]: key })), {}))
    .handleAction(createKey.success, (state, action) => {
    return Object.assign(Object.assign({}, state), { [action.payload.value]: action.payload });
})
    .handleAction([deleteKey.success], (state, action) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _a = state, _b = action.payload.id, _ = _a[_b], newState = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
    return newState;
});
const requests = combineReducers({
    list: createAsyncActionReducer([listKeys]),
    create: createAsyncActionReducer([createKey]),
    delete: createAsyncActionReducer([deleteKey])
});
export default combineReducers({ data, requests });
