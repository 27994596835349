/* eslint-disable import/prefer-default-export */
export class RequestError extends Error {
}
export class GenericError extends RequestError {
    constructor() {
        super("Something went wrong! Please wait a moment and try again.");
        if (Error.captureStackTrace !== undefined) {
            Error.captureStackTrace(this, GenericError);
        }
    }
}
