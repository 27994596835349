import { of, merge } from "rxjs";
import { filter, catchError, takeUntil, mergeMap, delay, take } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { logout } from "../actions/auth";
// eslint-disable-next-line import/prefer-default-export
export function catchAsyncActionErrors(action$, asyncAction) {
    return function op(src) {
        return src.pipe(catchError((error) => error.logout
            ? of(asyncAction.failure(error), logout())
            : of(asyncAction.failure(error))), mergeMap(action => merge(of(action).pipe(takeUntil(action$.pipe(filter(isActionOf(asyncAction.cancel))))), of(asyncAction.cancel()).pipe(delay(10000), take(1)))));
    };
}
