import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "./styles/screen.scss";
import store from "./store";
import ScrollManager from "./components/ScrollManager";
import App from "./components/App";
ReactDOM.render(React.createElement(Provider, { store: store },
    React.createElement(Router, null,
        React.createElement(ScrollManager, null),
        React.createElement(App, null))), document.getElementById("app"));
