import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { createEpicMiddleware } from "redux-observable";
import reducer from "../reducers";
import epic from "../epics";
import pojoize from "./pojoize";
import hydrate from "./hydrate";
const epicMiddleware = createEpicMiddleware();
const middlewares = [epicMiddleware, thunk, pojoize];
const middlewareEnhancer = applyMiddleware(...middlewares);
const store = createStore(reducer, middlewareEnhancer);
// This must be called after the store is created.
epicMiddleware.run(epic);
export default hydrate(store);
