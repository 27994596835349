import store from "store";
import { GenericError } from "./errors";
// Helper to convert typesafe-actions async actions into thunks.
export function toThunk(action, req) {
    return function fun(args) {
        return async (dispatch) => {
            dispatch(action.request());
            try {
                const payload = await req(args);
                dispatch(action.success(payload));
            }
            catch (error) {
                dispatch(action.failure(error));
            }
        };
    };
}
/**
 *  Creates a post request function for a given endpoint.
 *
 *  Returns a function that takes a post request body and returns a promise for the result.
 *
 *  Arguments:
 *    - route: The API route for the endpoint (e.g. "user/create").
 *    - expected: List of expected keys in the body.
 */
async function request(method, endpoint, options = {}) {
    /* eslint-disable dot-notation */
    if (!endpoint || endpoint[0] !== "/") {
        throw new Error("endpoint must be an absolute path");
    }
    const { headers: headersMap = {}, body: bodyMap = undefined } = options;
    let { token = undefined } = options;
    const url = `${window.location.origin}/api${endpoint}`;
    const headers = new Headers(headersMap);
    if (!headers.has("authorization")) {
        if (!token) {
            token = store.get("token");
        }
        if (token) {
            headers.set("authorization", `Bearer ${token}`);
        }
        else {
            // Explcitly disable auth to prevent browsers from sending basic auth.
            // This only matters when basic auth is enabled on our server (like on staging).
            headers.set("authorization", "");
        }
    }
    let body;
    if (bodyMap) {
        headers.set("content-type", "application/json");
        body = JSON.stringify(bodyMap);
    }
    const res = await fetch(url, { method, headers, body });
    const contentType = res.headers.get("content-type");
    if (!res.ok) {
        let err;
        if (contentType && contentType.includes("application/json")) {
            const { message } = await res.json();
            err = new Error(message);
        }
        else {
            err = new GenericError();
        }
        err.status = res.status;
        err.statusText = res.statusText;
        if (err.status === 401 && token === store.get("token") && token) {
            // we tried to use the default token and got 401; tell the reducer
            // to log the user out.
            err.logout = true;
        }
        return Promise.reject(err);
    }
    if (contentType && contentType.includes("application/json")) {
        return res.json();
    }
    return res.arrayBuffer();
}
// Define the API endpoints.
const api = {
    contact: (name, org, email, text) => request("POST", "/contact", {
        body: { name, org, email, text }
    }),
    createUser: (email, password, name, company) => request("POST", "/users", { body: { email, password, name, company } }),
    loginUser: (email, password) => request("GET", "/token", {
        headers: {
            authorization: `Basic ${Buffer.from(`${email}:${password}`).toString("base64")}`
        }
    }),
    changePassword: (email, currentPassword, newPassword) => request("PATCH", "/user", {
        headers: {
            authorization: `Basic ${Buffer.from(`${email}:${currentPassword}`).toString("base64")}`
        },
        body: { password: newPassword }
    }),
    verifyUser: (token) => request("POST", "/user/verify", { token }),
    recoverUser: (email) => request("POST", `/users/${email}/recover`),
    fetchUser: () => request("GET", "/user"),
    editUser: (fields, token) => request("PATCH", "/user", {
        body: fields,
        token
    }),
    fetchPaymentInfo: () => request("GET", "/user/payment-info"),
    updatePaymentInfo: (token) => request("PUT", "/user/payment-info", { body: { token } }),
    deletePaymentInfo: () => request("DELETE", "/user/payment-info"),
    fetchUpcomingInvoice: () => request("GET", "/user/invoices/upcoming"),
    deleteUser: () => request("DELETE", "/user"),
    listAPIKeys: () => request("GET", "/user/keys"),
    createAPIKey: (name) => request("POST", "/user/keys", { body: { name } }),
    fetchAPIKey: (id) => request("GET", `/user/keys/${id}`),
    deleteAPIKey: (id) => request("DELETE", `/user/keys/${id}`),
    fetchSpeakers: (token) => request("GET", `/speakers`, { token }),
    generateAudio: (text, options) => {
        const url = api.generateAudioUrl(text, options);
        return request("GET", `/generate${url.search}`);
    },
    generateAudioUrl: (text, options) => {
        const { speaker, style, ssml = false, token = undefined, download = false } = options;
        const url = new URL(window.location.origin);
        url.pathname = "/api/generate";
        url.searchParams.append("text", text);
        url.searchParams.append("speaker", speaker);
        url.searchParams.append("style", style);
        url.searchParams.append("ssml", ssml ? "true" : "false");
        if (token) {
            url.searchParams.append("token", token);
        }
        if (download) {
            url.searchParams.append("download", "true");
            url.searchParams.append("encoding", "wav");
        }
        return url;
    }
};
export default api;
