import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import { createAsyncActionReducer } from "./api";
import { fetchPaymentInfo, updatePaymentInfo, deletePaymentInfo, fetchUpcomingInvoice } from "../actions/billing";
import metrics from "../metrics";
const data = createReducer({})
    .handleAction(fetchPaymentInfo.success, (state, action) => (Object.assign(Object.assign({}, state), { info: action.payload })))
    .handleAction(updatePaymentInfo.success, (state, action) => {
    metrics.trackAddCreditCard();
    return Object.assign(Object.assign({}, state), { info: action.payload });
})
    .handleAction(deletePaymentInfo.success, (state, _action) => (Object.assign(Object.assign({}, state), { info: undefined })))
    .handleAction(fetchUpcomingInvoice.success, (state, action) => (Object.assign(Object.assign({}, state), { invoice: action.payload })));
const requests = combineReducers({
    fetchPaymentInfo: createAsyncActionReducer([fetchPaymentInfo]),
    updatePaymentInfo: createAsyncActionReducer([updatePaymentInfo]),
    deletePaymentInfo: createAsyncActionReducer([deletePaymentInfo]),
    fetchUpcomingInvoice: createAsyncActionReducer([fetchUpcomingInvoice])
});
export default combineReducers({ data, requests });
