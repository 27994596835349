import { createReducer } from "typesafe-actions";
export const createAsyncActionReducer = (actions) => {
    return createReducer({ loading: false })
        .handleAction(actions.map(action => action.request), (_state, _action) => ({
        loading: true
    }))
        .handleAction(actions.map(action => action.success), (_state, action) => ({
        loading: false,
        success: action.payload.message
            ? action.payload.message
            : true
    }))
        .handleAction(actions.map(action => action.failure), (_state, action) => ({
        loading: false,
        error: action.payload.message
    }))
        .handleAction(actions.map(action => action.cancel), (_state, _action) => ({
        loading: false
    }));
};
