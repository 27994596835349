import { combineEpics } from "redux-observable";
import { from, of } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import * as Sentry from "../sentry";
import { catchAsyncActionErrors } from "./api";
import api from "../api";
import { fetchPaymentInfo, updatePaymentInfo, deletePaymentInfo, fetchUpcomingInvoice } from "../actions/billing";
import { GenericError } from "../errors";
const fetchPaymentInfoEpic = action$ => action$.pipe(filter(isActionOf(fetchPaymentInfo.request)), switchMap(_request => from(api.fetchPaymentInfo()).pipe(map(fetchPaymentInfo.success), catchAsyncActionErrors(action$, fetchPaymentInfo))));
const updatePaymentInfoEpic = action$ => action$.pipe(filter(isActionOf(updatePaymentInfo.request)), switchMap(request => from(new Promise(async (resolve, reject) => {
    const { tokenPromise } = request.payload;
    try {
        const tokenResponse = await tokenPromise;
        if (tokenResponse.token) {
            resolve(api.updatePaymentInfo(tokenResponse.token.id));
        }
        else if (tokenResponse.error &&
            ["card_error", "validation_error"].includes(tokenResponse.error.type)) {
            reject(tokenResponse.error);
        }
        else {
            Sentry.captureException(new Error(`Stripe error: ${JSON.stringify(tokenResponse.error)}`));
            reject(new GenericError());
        }
    }
    catch (err) {
        Sentry.captureException(new Error(`Stripe error: ${JSON.stringify(err)}`));
        reject(new GenericError());
    }
})).pipe(catchAsyncActionErrors(action$, updatePaymentInfo), switchMap(res => isActionOf([updatePaymentInfo.failure, updatePaymentInfo.cancel])(res)
    ? of(res)
    : from(api.fetchPaymentInfo()).pipe(map(updatePaymentInfo.success), catchAsyncActionErrors(action$, updatePaymentInfo))))));
const deletePaymentInfoEpic = action$ => action$.pipe(filter(isActionOf(deletePaymentInfo.request)), switchMap(_request => from(api.deletePaymentInfo()).pipe(map(deletePaymentInfo.success), catchAsyncActionErrors(action$, deletePaymentInfo))));
const fetchUpcomingInvoiceEpic = action$ => action$.pipe(filter(isActionOf(fetchUpcomingInvoice.request)), switchMap(_request => from(api.fetchUpcomingInvoice()).pipe(map(fetchUpcomingInvoice.success), catchAsyncActionErrors(action$, fetchUpcomingInvoice))));
export default combineEpics(fetchPaymentInfoEpic, updatePaymentInfoEpic, deletePaymentInfoEpic, fetchUpcomingInvoiceEpic);
