import { combineReducers } from "redux";
import { getType } from "typesafe-actions";
import { logout } from "../actions/auth";
import auth from "./auth";
import billing from "./billing";
import contact from "./contact";
import engine from "./engine";
import keys from "./keys";
import user from "./user";
const reducer = combineReducers({
    auth,
    billing,
    contact,
    engine,
    keys,
    user
});
// Reset the state of all reducers on logout.
export default (state, action) => {
    if (action.type === getType(logout)) {
        return reducer(undefined, action);
    }
    return reducer(state, action);
};
