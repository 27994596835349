import store from "store";
import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import metrics from "../metrics";
import { createAsyncActionReducer } from "./api";
import { login, logout, createAccount, verifyAccount, recoverPassword, resetPassword } from "../actions/auth";
const data = createReducer({})
    .handleAction(login.success, (_state, action) => {
    const { token } = action.payload;
    metrics.trackLogin(token);
    store.set("token", token);
    return { token };
})
    .handleAction(logout, (_state, _action) => {
    metrics.trackLogout();
    store.remove("token");
    return {};
})
    .handleAction(createAccount.success, (state, action) => {
    metrics.trackSignup(action.payload.userid);
    return state;
});
const requests = combineReducers({
    login: createAsyncActionReducer([login]),
    createAccount: createAsyncActionReducer([createAccount]),
    verifyAccount: createAsyncActionReducer([verifyAccount]),
    recoverPassword: createAsyncActionReducer([recoverPassword]),
    resetPassword: createAsyncActionReducer([resetPassword])
});
export default combineReducers({ data, requests });
