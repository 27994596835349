const lazy = async () => {
    return import(/* webpackChunkName: "sentry" */ "@sentry/browser");
};
export const init = async (options) => {
    const sentry = await lazy();
    if (window.env.SENTRY_DSN) {
        // Remove basic error handlers as Sentry installs better ones.
        window.onerror = () => { };
        window.onunhandledrejection = () => { };
        // Remove console logging in production as Sentry will capture it as breadcrumbs.
        if (process.env.NODE_ENV === "production") {
            window.console.log = () => { };
            window.console.debug = () => { };
            window.console.info = () => { };
            window.console.warn = () => { };
            window.console.error = (err) => {
                sentry.captureException(err);
            };
        }
        sentry.init(options);
    }
};
export const captureException = async (err) => {
    const sentry = await lazy();
    return sentry.captureException(err);
};
// Add basic error handlers to manage errors before Sentry loads.
window.onerror = (message, url, line, column, error) => {
    if (error) {
        return captureException(error);
    }
    return captureException(message);
};
window.onunhandledrejection = (event) => captureException(event.reason);
