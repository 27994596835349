import { combineEpics } from "redux-observable";
import { from } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { catchAsyncActionErrors } from "./api";
import api from "../api";
import { fetchUser, updateProfile, updateEmail, updatePassword, deleteUser } from "../actions/user";
const fetchUserEpic = action$ => action$.pipe(filter(isActionOf(fetchUser.request)), switchMap(_request => from(api.fetchUser()).pipe(map(fetchUser.success), catchAsyncActionErrors(action$, fetchUser))));
const updateProfileEpic = action$ => action$.pipe(filter(isActionOf(updateProfile.request)), switchMap(request => from(api.editUser(request.payload)).pipe(map(() => updateProfile.success(request.payload)), catchAsyncActionErrors(action$, updateProfile))));
const updateEmailEpic = action$ => action$.pipe(filter(isActionOf(updateEmail.request)), switchMap(request => from(api.editUser(request.payload)).pipe(map(() => updateEmail.success()), catchAsyncActionErrors(action$, updateEmail))));
const updatePasswordEpic = (action$, state$) => action$.pipe(filter(isActionOf(updatePassword.request)), switchMap(request => from(new Promise((resolve, reject) => {
    const { user } = state$.value.user.data;
    const { oldPassword, newPassword, confirmNewPassword } = request.payload;
    if (!oldPassword) {
        reject(new Error("Current password cannot be blank."));
    }
    else if (!newPassword) {
        reject(new Error("New password cannot be blank."));
    }
    else if (newPassword !== confirmNewPassword) {
        reject(new Error("Password confirmation does not match."));
    }
    else if (!user) {
        reject(new Error("Invalid user authentication."));
    }
    else {
        resolve(api.changePassword(user.email, oldPassword, newPassword));
    }
})).pipe(map(() => updatePassword.success()), catchAsyncActionErrors(action$, updatePassword))));
const deleteUserEpic = action$ => action$.pipe(filter(isActionOf(deleteUser.request)), switchMap(_request => from(api.deleteUser()).pipe(map(deleteUser.success), catchAsyncActionErrors(action$, deleteUser))));
export default combineEpics(fetchUserEpic, updateProfileEpic, updateEmailEpic, updatePasswordEpic, deleteUserEpic);
