import * as Sentry from "./sentry";
import loadScript from "./script-loader";
async function init(chatId, useDefaultLauncher = true) {
    try {
        await loadScript("https://chat-assets.frontapp.com/v1/chat.bundle.js");
        if (window.FrontChat) {
            window.FrontChat("init", {
                chatId,
                useDefaultLauncher
            });
        }
        else {
            Sentry.captureException(new Error("Front chat failed to load."));
        }
    }
    catch (err) {
        Sentry.captureException(err);
    }
}
function show() {
    if (window.FrontChat) {
        window.FrontChat("show");
    }
}
function hide() {
    if (window.FrontChat) {
        window.FrontChat("hide");
    }
}
export default {
    init,
    show,
    hide
};
