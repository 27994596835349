/* eslint-disable @typescript-eslint/camelcase */
import gtag, { Conversions } from "./gtag";
const metrics = {
    init: gtag.init,
    trackPageLoad: (_props) => {
        gtag.pageview();
    },
    trackSignup: (id) => {
        gtag.set({ user_id: id });
        gtag.conversion(Conversions.CreateAccount);
    },
    trackUser: (props) => {
        gtag.set({ user_id: props.id });
    },
    trackLogin: (_token) => {
        gtag.event("login", { event_category: "user", method: "email" });
    },
    trackLogout: () => {
        gtag.event("logout", { event_category: "user" });
    },
    trackAddCreditCard: () => {
        gtag.conversion(Conversions.AddCreditCard);
    },
    trackContactViaChat: () => {
        gtag.conversion(Conversions.ContactViaChat);
    },
    trackContactViaForm: (loc) => {
        gtag.conversion(loc === "home"
            ? Conversions.ContactViaHomeForm
            : Conversions.ContactViaDemosForm);
    }
};
export default metrics;
