const loadScript = (src) => {
    return new Promise(function promise(resolve, reject) {
        const script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function load() {
            resolve();
        });
        script.addEventListener("error", function error(e) {
            reject(e);
        });
        document.body.appendChild(script);
    });
};
export default loadScript;
