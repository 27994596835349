import browserStore from "store";
// import the Store type definition from Redux
import { login } from "../actions/auth";
export default function hydrate(reduxStore) {
    const token = browserStore.get("token");
    if (token) {
        reduxStore.dispatch(login.success({ token }));
    }
    return reduxStore;
}
