import { combineEpics } from "redux-observable";
import { from } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { catchAsyncActionErrors } from "./api";
import api from "../api";
import { login, createAccount, verifyAccount, recoverPassword, resetPassword } from "../actions/auth";
const loginEpic = action$ => action$.pipe(filter(isActionOf(login.request)), switchMap(request => from(new Promise((resolve, reject) => {
    const { email, password } = request.payload;
    if (!email) {
        reject(new Error("Email cannot be blank."));
    }
    else if (!password) {
        reject(new Error("Password cannot be blank."));
    }
    else {
        resolve(api.loginUser(email, password));
    }
})).pipe(map(login.success), catchAsyncActionErrors(action$, login))));
const createAccountEpic = action$ => action$.pipe(filter(isActionOf(createAccount.request)), switchMap(request => from(new Promise((resolve, reject) => {
    const { email, password, confirmPassword, name, company, agree } = request.payload;
    if (!email || !password) {
        reject(new Error(`${!email ? "Email" : "Password"} cannot be blank.`));
    }
    else if (password !== confirmPassword) {
        reject(new Error("Password fields do not match."));
    }
    else if (!agree) {
        reject(new Error("You must agree to the Terms of Service and Privacy Policy."));
    }
    else {
        resolve(api.createUser(email, password, name, company));
    }
})).pipe(map(createAccount.success), catchAsyncActionErrors(action$, createAccount))));
const verifyAccountEpic = action$ => action$.pipe(filter(isActionOf(verifyAccount.request)), switchMap(request => from(api.verifyUser(request.payload.token)).pipe(map(verifyAccount.success), catchAsyncActionErrors(action$, verifyAccount))));
const recoverPasswordEpic = action$ => action$.pipe(filter(isActionOf(recoverPassword.request)), switchMap(request => from(new Promise((resolve, reject) => {
    if (!request.payload.email) {
        reject(new Error("Email cannot be blank"));
    }
    else {
        resolve(api.recoverUser(request.payload.email));
    }
})).pipe(map(recoverPassword.success), catchAsyncActionErrors(action$, recoverPassword))));
const resetPasswordEpic = action$ => action$.pipe(filter(isActionOf(resetPassword.request)), switchMap(request => from(new Promise((resolve, reject) => {
    const { token, password, confirmPassword } = request.payload;
    if (typeof token !== "string") {
        reject(new Error("Invalid password recovery token."));
    }
    else if (!password) {
        reject(new Error("Password cannot be blank."));
    }
    else if (password !== confirmPassword) {
        reject(new Error("Password fields do not match"));
    }
    else {
        resolve(api.editUser({ password }, token));
    }
})).pipe(map(resetPassword.success), catchAsyncActionErrors(action$, resetPassword))));
export default combineEpics(loginEpic, createAccountEpic, verifyAccountEpic, recoverPasswordEpic, resetPasswordEpic);
