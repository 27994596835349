import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import { fetchUser } from "../actions/user";
import { logout } from "../actions/auth";
import ImgLogo from "../assets/images/logo.svg";
import ImgArrowDown from "../assets/images/arrow-d.svg";
const LINKS = [
    { href: "/demos", name: "Demos" },
    { href: "/docs", name: "Documentation" },
    { href: "/careers", name: "Careers" },
];
const LinkWrapper = ({ href, name, external = false, onClick }) => {
    if (external) {
        return (React.createElement("a", { href: href, onClick: onClick }, name));
    }
    return (React.createElement(NavLink, { to: href, onClick: onClick }, name));
};
const toggleDropdownMenu = () => {
    const menu = document.querySelector(".user-menu");
    if (menu) {
        if (menu.classList.contains("fade-in")) {
            menu.classList.replace("fade-in", "fade-out");
        }
        else {
            menu.classList.remove("fade-out");
            menu.classList.add("fade-in");
        }
    }
};
const fadeOutDropdownMenu = () => {
    const menu = document.querySelector(".user-menu");
    if (menu) {
        menu.classList.remove("fade-in");
        menu.classList.add("fade-out");
    }
};
// Finds the closest element at or above the current element matching a predicate.
function closest(el, predicate) {
    if (!el) {
        return el;
    }
    return predicate(el) ? el : closest(el.parentElement, predicate);
}
const fadeOutDropdownMenuWhenClickAway = (e) => {
    const target = e.target;
    if (closest(target, el => el.classList.contains("user-menu") ||
        el.classList.contains("user-menu-wrap"))) {
        return true;
    }
    fadeOutDropdownMenu();
    return true;
};
const toggleHamburgerMenu = () => {
    const containerMob = document.querySelector(".main-header_d-menu");
    const mobMenu = document.querySelector(".mob-menu");
    if (containerMob && mobMenu) {
        containerMob.classList.toggle("active");
        mobMenu.classList.toggle("active");
    }
};
const Navigation = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { token: loggedIn } = useSelector((state) => state.auth.data);
    const user = useSelector((state) => state.user.data.user);
    const email = user ? user.email : "";
    const loginNav = email ? (React.createElement("div", { className: "user-wrap user-menu-wrap" },
        React.createElement("div", { className: "user-active", style: { padding: "6px 0px 6px 0px" } },
            React.createElement("div", { className: "username" }, email),
            React.createElement("i", { className: "icon-arrow" },
                React.createElement("img", { src: ImgArrowDown, alt: "arrow" }))),
        React.createElement("div", { className: "user-menu fade-out" },
            React.createElement("ul", null,
                React.createElement("li", null,
                    React.createElement(NavLink, { to: "/dashboard", onClick: fadeOutDropdownMenu }, "Dashboard")),
                React.createElement("li", null,
                    React.createElement(NavLink, { to: "/settings", onClick: fadeOutDropdownMenu }, "Settings")),
                React.createElement("li", null,
                    React.createElement(Link, { to: "/login", onClick: () => {
                            dispatch(logout());
                            fadeOutDropdownMenu();
                        } }, "Log Out")))))) : (React.createElement("div", { className: "user-wrap" },
        React.createElement(Link, { to: "/login", className: "btn-border-blue" }, "Login")));
    const loginHamburgerNav = loggedIn ? (React.createElement(React.Fragment, null,
        React.createElement("li", null,
            React.createElement(NavLink, { to: "/dashboard", onClick: toggleHamburgerMenu }, "Dashboard")),
        React.createElement("li", null,
            React.createElement(NavLink, { to: "/settings", onClick: toggleHamburgerMenu }, "Settings")),
        React.createElement("li", null,
            React.createElement(NavLink, { to: "/login", onClick: () => {
                    dispatch(logout());
                    toggleHamburgerMenu();
                } }, "Log Out")))) : (React.createElement(React.Fragment, null,
        React.createElement("li", null,
            React.createElement(NavLink, { to: "/login", onClick: toggleHamburgerMenu }, "Login"))));
    useEffect(() => {
        if (loggedIn && !email) {
            dispatch(fetchUser.request());
        }
    }, [dispatch, loggedIn, email]);
    useEffect(() => {
        const userMenu = document.querySelector(".user-active");
        if (userMenu) {
            userMenu.addEventListener("click", toggleDropdownMenu);
        }
        document.addEventListener("click", fadeOutDropdownMenuWhenClickAway);
        return () => {
            if (userMenu) {
                userMenu.removeEventListener("click", toggleDropdownMenu);
            }
            document.removeEventListener("click", fadeOutDropdownMenuWhenClickAway);
        };
    }, [loginNav]);
    useEffect(() => {
        const hamburgerMenu = document.querySelector(".hamburger");
        if (hamburgerMenu) {
            hamburgerMenu.addEventListener("click", toggleHamburgerMenu);
        }
        return () => {
            if (hamburgerMenu) {
                hamburgerMenu.removeEventListener("click", toggleHamburgerMenu);
            }
        };
    }, []);
    const getMainHeaderClass = () => {
        if (location.pathname === "/" || location.pathname === "/demos") {
            return "";
        }
        if (location.pathname === "/docs") {
            return "main-header_fix";
        }
        return "main-header_rel";
    };
    return (React.createElement("header", { className: `main-header ${getMainHeaderClass()}` },
        React.createElement("div", { className: "container-fluid" },
            React.createElement("div", { className: "row justify-content-around justify-content-lg-center" },
                React.createElement("div", { className: "col-2" },
                    React.createElement("div", { className: "main-header_logo" },
                        React.createElement(Link, { to: "/", title: "Home Page" },
                            React.createElement("img", { src: ImgLogo, alt: "logo" })))),
                React.createElement("div", { className: "col-6 col-lg-5" },
                    React.createElement("div", { className: "main-header_menu row" }, LINKS.map(link => (React.createElement("div", { className: "col", key: link.name },
                        React.createElement(LinkWrapper, Object.assign({}, link))))))),
                React.createElement("div", { className: "col-2" },
                    loginNav,
                    React.createElement("div", { className: "main-header_d-menu" },
                        React.createElement("div", { className: "hamburger" },
                            React.createElement("span", null),
                            React.createElement("span", null),
                            React.createElement("span", null)))))),
        React.createElement("div", { className: "mob-menu" },
            React.createElement("div", { className: "mob-menu_bg" }),
            React.createElement("ul", null,
                LINKS.map(link => (React.createElement("li", { key: link.name },
                    React.createElement(LinkWrapper, Object.assign({}, link, { onClick: toggleHamburgerMenu }))))),
                loginHamburgerNav))));
};
export default Navigation;
