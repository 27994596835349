import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import metrics from "../metrics";
import { createAsyncActionReducer } from "./api";
import { fetchUser, updateProfile, updateEmail, updatePassword, deleteUser } from "../actions/user";
const data = createReducer({})
    .handleAction(fetchUser.success, (_state, action) => {
    const { id, email } = action.payload;
    metrics.trackUser({ id, email });
    return {
        user: action.payload
    };
})
    .handleAction(updateProfile.success, (state, action) => {
    if (state.user) {
        return Object.assign(Object.assign({}, state), { user: Object.assign(Object.assign({}, state.user), action.payload) });
    }
    return state;
})
    .handleAction(deleteUser.success, (_state, _action) => ({}));
const requests = combineReducers({
    fetch: createAsyncActionReducer([fetchUser]),
    update: combineReducers({
        profile: createAsyncActionReducer([updateProfile]),
        email: createAsyncActionReducer([updateEmail]),
        password: createAsyncActionReducer([updatePassword])
    }),
    delete: createAsyncActionReducer([deleteUser])
});
export default combineReducers({ data, requests });
