import { combineEpics } from "redux-observable";
import { from } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { catchAsyncActionErrors } from "./api";
import api from "../api";
import { listKeys, createKey, deleteKey } from "../actions/keys";
const listKeysEpic = action$ => action$.pipe(filter(isActionOf(listKeys.request)), switchMap(_request => from(api.listAPIKeys()).pipe(map(listKeys.success), catchAsyncActionErrors(action$, listKeys))));
const createKeyEpic = action$ => action$.pipe(filter(isActionOf(createKey.request)), switchMap(request => from(api.createAPIKey(request.payload.name)).pipe(map(createKey.success), catchAsyncActionErrors(action$, createKey))));
const deleteKeyEpic = action$ => action$.pipe(filter(isActionOf(deleteKey.request)), switchMap(request => from(api.deleteAPIKey(request.payload.id)).pipe(map(() => deleteKey.success(request.payload)), catchAsyncActionErrors(action$, deleteKey))));
export default combineEpics(listKeysEpic, createKeyEpic, deleteKeyEpic);
