/* eslint-disable @typescript-eslint/camelcase */
import loadScript from "./script-loader";
export var Conversions;
(function (Conversions) {
    Conversions["ViewHome"] = "AW-740482128/kw3SCMS1uZ8BENC4i-EC";
    Conversions["ViewCareers"] = "AW-740482128/UlgBCNDHuZ8BENC4i-EC";
    Conversions["ViewDemos"] = "AW-740482128/70MICPXFuZ8BENC4i-EC";
    Conversions["ViewDocs"] = "AW-740482128/XjNiCNzHuZ8BENC4i-EC";
    Conversions["CreateAccount"] = "AW-740482128/tDnuCO6zmZ8BENC4i-EC";
    Conversions["AddCreditCard"] = "AW-740482128/pMqbCP_srp8BENC4i-EC";
    Conversions["ContactViaChat"] = "AW-740482128/GruQCP7Wp58BENC4i-EC";
    Conversions["ContactViaHomeForm"] = "AW-740482128/Vx_sCMjLuZ8BENC4i-EC";
    Conversions["ContactViaDemosForm"] = "AW-740482128/jkWPCMHiossBENC4i-EC";
})(Conversions || (Conversions = {}));
const PAGEVIEW_CONVERSIONS = {
    "/": Conversions.ViewHome,
    "/careers": Conversions.ViewCareers,
    "/demos": Conversions.ViewDemos,
    "/docs": Conversions.ViewDocs
};
class GlobalSiteTag {
    constructor(analyticsId, adwordsId, disabled) {
        this.analyticsId = analyticsId;
        this.adwordsId = adwordsId;
        this.disabled = disabled || false;
    }
    init() {
        if (!this.disabled) {
            loadScript(`https://www.googletagmanager.com/gtag/js?id=${this.analyticsId ? this.analyticsId : this.adwordsId}`);
        }
    }
    config(id, options = {}) {
        if (window.gtag && !this.disabled) {
            window.gtag("config", id, options);
        }
    }
    event(name, options = {}) {
        if (window.gtag && !this.disabled) {
            window.gtag("event", name, options);
        }
    }
    set(pairs = {}) {
        if (window.gtag && !this.disabled) {
            window.gtag("set", pairs);
        }
    }
    conversion(id) {
        if (this.adwordsId) {
            this.event("conversion", { send_to: id });
        }
    }
    pageview(options = {}) {
        const { page_title = document.title, page_location = document.location.href, page_path = document.location.pathname } = options;
        if (this.analyticsId) {
            this.config(this.analyticsId, {
                page_title,
                page_location,
                page_path
            });
            if (page_path in PAGEVIEW_CONVERSIONS) {
                this.conversion(PAGEVIEW_CONVERSIONS[page_path]);
            }
        }
    }
}
const getGlobalSiteTag = () => {
    const disabled = !window.gtag ||
        (!process.env.UA_TRACKING_ID && !process.env.AW_TRACKING_ID);
    const gtag = new GlobalSiteTag(process.env.UA_TRACKING_ID, process.env.AW_TRACKING_ID, disabled);
    if (disabled) {
        // eslint-disable-next-line no-console
        console.log("Google Analytics/Adwords are disabled.");
    }
    return gtag;
};
export default getGlobalSiteTag();
