import { from } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { catchAsyncActionErrors } from "./api";
import api from "../api";
import { contact } from "../actions/contact";
const contactEpic = action$ => action$.pipe(filter(isActionOf(contact.request)), switchMap(request => from(new Promise((resolve, reject) => {
    const { name, email, text } = request.payload;
    if (!name || !email || !text) {
        reject(new Error("Please complete the entire form."));
    }
    else {
        resolve(api.contact(name, "", email, text));
    }
})).pipe(map(contact.success), catchAsyncActionErrors(action$, contact))));
export default contactEpic;
